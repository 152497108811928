import React, { useEffect, useState, useCallback } from 'react';
import { Form, Row, Col, Card, message } from 'antd';
import { Button } from 'antd';
import { Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { callResetPw, resetPw } from 'store/slices/authSlice';

export default function ResetPw(props) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = (e) => {
    const payload = form.getFieldsValue();
    if (payload.account.includes('W')) {
      message.warning('ID를 입력해주시기 바랍니다.');
      return;
    }
    dispatch(resetPw(payload));
  };

  const onBackToLogin = () => {
    dispatch(callResetPw({ callReset: false }));
  };

  return (
    <Row align="middle" justify="space-around" style={{ height: '100vh' }}>
      <Col span={6}>
        <Card style={{ padding: 24 }}>
          <h2 style={{ marginBottom: 40 }}>비밀번호 초기화</h2>

          <Form
            name="basic"
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            form={form}
            onFinish={() => onFinish()}
            autoComplete="off"
          >
            <Form.Item
              label="ID"
              name="account"
              rules={[
                {
                  required: true,
                  message: 'ID를 입력해주시기 바랍니다.',
                },
              ]}
            >
              <Input onChange={(e) => form.setFieldValue('account', e.target.value)} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                초기화
              </Button>
            </Form.Item>
          </Form>
          <div>
            <Button type="link" style={{ float: 'right' }} onClick={() => onBackToLogin()}>
              돌아가기
            </Button>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
