import { getUserList } from 'api/UserApi';
import { useGetItems } from 'hooks/useFetchItems';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function useUser() {
  const [userList, setUserList] = useState([]);
  const page = 0;
  const size = 2147483647;
  const user = useSelector((state) => state.auth);
  const coId = user?.coId ?? null;
  const { data } = useGetItems({
    key: ['user', coId, page, size],
    getFunction: async () =>
      getUserList({
        page: 0,
        size: 2147483647,
        coId,
      }),
    enabled: !!coId,
  });
  useEffect(() => {
    if (!data) return;
    setUserList(data.content);
  }, [data]);

  const isCurrentUser = useCallback(
    /**
     * 인자로 userId를 받아서 내 userId와 같은지 대조한다.
     * 다음과 같이 사용 가능하다.
     * const isDeleteButtonValid = isCurrentUser(frstRegrId);
     * ...
     * isDeleteButtonValid && DELETE_BUTTON && <DELETE_BUTTON ... />
     * @param {string} userId
     */
    (userId) => {
      if (!user || !user?.userId || !userId) return;
      return userId == user?.userId;
    },
    [user],
  );
  const isCurrentCompany = useCallback(
    /**
     * 인자로 coId를 받아서 내 coId와 같은지 대조한다.
     * 다음과 같이 사용 가능하다.
     * const isDeleteButtonValid = isCurrentCompany(frstRegrId);
     * ...
     * isDeleteButtonValid && DELETE_BUTTON && <DELETE_BUTTON ... />
     * @param {string} coId
     */
    (coId) => {
      if (!user || !user?.coId || !coId) return;
      return coId == user?.coId;
    },
    [user],
  );

  return { user, userList, isCurrentUser, isCurrentCompany };
}
