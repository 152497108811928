import * as Components from 'components';

export function ComponentMappingByRoleService(response) {
  const cmpObj = {};
  if (!response?.length) return cmpObj;

  const menuPath = response[0]?.menuPath;

  const Page = Components[menuPath];
  response.forEach((item) => {
    /* 
      I. hideYn이 "N"인 컴포넌트의 ID만 가져오기 
      II. 단, 수정 기능(MODIFY)는 hideYn 분기를 거치지 않고 각 화면에서 컴포넌트를 보여줄지 말지 결정한다.
    */
    if (!item.cmpNm.includes('MODIFY') && item.hideYn === 'N') {
      const PageComponent = Page[item.cmpNm];
      if (PageComponent) {
        let disabled = item.disableYn === 'Y' ? true : false;
        PageComponent.props = { disabled };
        cmpObj[item.cmpNm] = PageComponent;
      }
    } else if (item.cmpNm.includes('MODIFY')) {
      const ModifyComponent = Page[item.cmpNm];
      cmpObj[item.cmpNm] = ModifyComponent;
    }
  });
  return cmpObj;
}
