import { Popover } from 'antd';
import { Alert, Modal } from 'components/atoms/Modal';
import Tabs from 'components/atoms/Tabs';
import NotOpenPage from 'components/molecules/NotOpenPage';
import mobileMenuIdArr from 'constants/mobileMenus';
import useModal from 'hooks/useModal';
import useTabs from 'hooks/useTabs';
import useUser from 'hooks/useUser';
import _ from 'lodash';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getMenusRequest, menuStoreItems } from 'store/slices/menuSlice';
import GnbMenu from 'templates/pagelayout/GnbMenu';
import LnbMenu from 'templates/pagelayout/LnbMenu';
import TabPopoverContent from 'templates/popover/TabPopoverContent';

// 재귀적으로 돌면서 popupYn이 Y인 컴포넌트를 제거하고, 이에 따라 자식이 없어진 페이지를 관리한다.
const recurseFilterPopup = (menus) =>
  _.filter(
    menus.map((item) => {
      if (item.popupYn == 'Y') return null;
      if (item?.children) {
        const filteredChildren = recurseFilterPopup(item.children);
        return {
          ...item,
          children: filteredChildren.length == 0 ? undefined : filteredChildren,
        };
      }
      return {
        ...item,
      };
    }),
    (m) => !!m,
  );

function DynamicComponentLoader(props) {
  const { menuId, path } = props;
  const [PageComponent, setPageComponent] = useState();
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadComponent = async () => {
      try {
        let Dynamic;
        if (menuId) {
          Dynamic = React.lazy(() =>
            import(`../../pages${path}`).catch((error) => {
              return { default: () => <NotOpenPage {...props} /> };
            }),
          );
          setPageComponent(() => Dynamic);
          setError(null);
        }
      } catch (error) {
        console.error('Error loading component:', error);
        setPageComponent(null);
        setError(error.message);
      }
    };

    loadComponent();
  }, [path, menuId]);

  if (error) {
    return <div>Error loading component: {error}</div>;
  }

  return (
    PageComponent && (
      <Suspense fallback={<div></div>}>
        <PageComponent {...props} />
      </Suspense>
    )
  );
}

function Layout(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  useUser();

  const menus = useSelector(menuStoreItems);
  const { user } = useSelector((state) => ({
    user: state?.auth,
  }));
  const { tabs, selectedLnb } = useSelector((state) => ({
    tabs: state?.tabs?.items,
    selectedLnb: state?.tabs?.selectedLnb,
  }));
  const { modal, alert } = useSelector((state) => ({
    modal: state?.modal?.items,
    alert: state?.modal?.alertItems,
  }));
  const { handleOpenTabs, handleCloseTabs, handleSelectTabs, handleOpenHomeTab } = useTabs();
  const { handleCloseModal } = useModal();
  const mobileMenus = useMemo(() => menus?.filter((menu) => mobileMenuIdArr.includes(menu?.menuId)) ?? [], [menus]);
  const [selectedGnb, selectGnb] = useState('');
  const [gnbMenus, setGnbMenus] = useState([]);
  const [lnbMenus, setLnbMenus] = useState([]);
  const [tabItems, setTabItems] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  /* 임시 팝오버 상태 => hook으로 예정 */
  // const [visiblePopover, setVisiblePopover] = useState(false);

  const languageOptions = [
    {
      value: 'korean',
      label: '한국어',
    },
    {
      value: 'english',
      label: 'English',
    },
    {
      value: 'china',
      label: '中國語',
    },
  ];

  const onChangeLanguage = (lang) => {
    console.log(`lang ${lang}`);
  };

  const handleSetMobile = (checked) => {
    if (checked === true) {
      setIsMobile(true);
      const mobileMain = mobileMenus.find((menu) => menu?.menuId == 'MEUI_00033');
      if (!mobileMain) return;
      handleOpenTabs(mobileMain);

      document.documentElement.requestFullscreen();
    }
  };

  const handleUnsetMobile = (flag) => {
    handleCloseModal();
    if (flag === true)
      document
        .exitFullscreen()
        /**
         * 사용자가 esc, f11 등의 입력으로 임의로 전체화면을 벗어난 상태에서
         * exitFullscreen을 호출하면 에러가 뜸.
         * 이를 해결하기 위해 빈 catch 문을 넣고
         * 상태 변경 로직을 finally에 넣음. (성공하든 실패하든 모달은 닫히기 때문)
         */
        .catch(() => {})
        .finally(() => {
          setIsMobile(false);
        });
  };

  /** URL과 탭 화면 연결하기
   */
  const mappingUrlTab = (url) => {
    const splitUrl = url.split('/');
    const page = splitUrl[splitUrl?.length - 1];
    if (!page) return;
    if (!menus?.length) return;

    if (page === 'dxp') {
      handleOpenHomeTab();
    } else {
      /* HOME 화면은 고정 */
      handleOpenHomeTab();

      const flattenMenusList = flatMenus(menus);
      const topmostId = findTopmostGnbId(flattenMenusList, page);
      const menu = flattenMenusList?.find((item) => item?.menuId === page);
      selectGnb(topmostId);
      handleOpenTabs(menu);
    }
  };

  /* 페이지 리스트 뽑아내기 */
  const flatMenus = (nestedMenus) => {
    const result = [];
    const flatten = (items) => {
      items.forEach((item) => {
        result.push(item);
        if (item.children) {
          flatten(item.children);
        }
      });
    };
    flatten(nestedMenus);
    return result;
  };

  // Recursive function to find the topmost GNB menu ID
  const findTopmostGnbId = (menus, targetId) => {
    const menuMap = {};
    menus.forEach((menu) => {
      menuMap[menu?.menuId] = menu;
    });

    let currentMenu = menuMap[targetId];
    while (currentMenu && currentMenu?.prntsMenuId) {
      currentMenu = menuMap[currentMenu?.prntsMenuId];
    }
    return currentMenu ? currentMenu?.menuId : null;
  };

  const handleTabItems = () => {
    const renderTab = (title, key) => {
      const tabEventObj = {
        activeKey: key,
        activeTabs: tabs,
      };

      return (
        <Popover trigger="contextMenu" content={<TabPopoverContent>{tabEventObj}</TabPopoverContent>}>
          <span>{title}</span>
        </Popover>
      );
    };

    const componentTabs = tabs
      ?.filter((item) => item.menuId)
      ?.map((item) => {
        const newItem = {
          ...item,
          children: <DynamicComponentLoader {...item} {...user} />,
        };

        if (item?.menuId === 'home') {
          newItem.closable = false;
        }

        return {
          ...newItem,
          label: renderTab(item?.menuNm, item?.menuId),
        };
      });

    setTabItems(componentTabs);
  };

  /* Tab 클릭으로 LNB 메뉴 Focusing */
  useEffect(() => {
    if (!menus?.length) return;

    const flattenMenusList = flatMenus(menus);
    const topmostId = findTopmostGnbId(flattenMenusList, selectedLnb);
    if (topmostId) {
      selectGnb(topmostId);
    }
  }, [selectedLnb, menus]);

  /* GNB, LNB 목록 상태저장 */
  useEffect(() => {
    setGnbMenus(
      _.filter(menus, function (item) {
        return !item?.prntsMenuId && item?.menuId !== 'MEUI_00033';
      }).map((item) => {
        return {
          ...item,
          key: item?.menuId,
          label: item.menuNm,
        };
      }),
    );
    const newLnbMenus = _.filter(menus, function (item) {
      return item?.prntsMenuId;
    }).map((item) => {
      return {
        ...item,
        key: item?.menuId,
        label: item.menuNm,
        children: item.children,
      };
    });
    setLnbMenus(recurseFilterPopup(newLnbMenus));
    mappingUrlTab(location?.pathname);
  }, [menus, location]);

  /* 탭으로 해당 페이지(컴포넌트) 열기 */
  useEffect(() => {
    handleTabItems();
  }, [tabs]);

  /* 최초 메뉴목록 조회하기 */
  useEffect(() => {
    dispatch(getMenusRequest(user));
  }, [user]);

  return (
    <div>
      <GnbMenu
        gnbMenus={gnbMenus}
        selectGnb={selectGnb}
        languageOptions={languageOptions}
        onChangeLanguage={onChangeLanguage}
        handleSetMobile={handleSetMobile}
        isMobile={isMobile}
        handleUnsetMobile={handleUnsetMobile}
      >
        <LnbMenu
          lnbMenus={lnbMenus}
          selectedGnb={selectedGnb}
          selectedLnb={selectedLnb}
          handleOpenTabs={handleOpenTabs}
          isMobile={isMobile}
          mobileMenus={mobileMenus}
        >
          <Tabs
            isMobile={isMobile}
            tabItems={tabItems}
            activeKey={selectedLnb}
            onTabClick={handleSelectTabs}
            onEdit={handleCloseTabs}
          />
        </LnbMenu>
      </GnbMenu>
      <Modal {...modal} />
      <Alert {...alert} />
    </div>
  );
}

export default Layout;
