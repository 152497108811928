import { checkAuthStatement } from 'api/AuthApi';
import { useSaveItems } from 'hooks/useFetchItems';
import * as pages from 'pages';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import * as ROUTES from './router';

// eslint-disable-next-line react/function-component-definition
const AppRouter = () => {
  const { auth } = useSelector((state) => ({
    auth: state.auth,
  }));
  const [flag, setFlag] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { mutate: checkAuthMutate } = useSaveItems({
    saveFunction: checkAuthStatement,
  });

  /**
   * 1) 최초 접근
   * 쿠키 없음, auth 없음
   * -> navigate(ROUTES.LOGIN) - return
   *
   * 2) auth가 있음
   * 받아오는 게 아니기 때문에 첫 렌더링부터 있을 것
   * 쿠키가 있는지 없는지 프론트에서는 모름
   * 2-1) statement 요청기록이 있음
   * navigate(ROUTES.DXP)
   * return
   * 2-2) statement 요청기록이 없음
   * statement로 요청
   * 성공시 navigate(ROUTES.DXP)
   * 실패시 로그아웃처리
   */
  useEffect(() => {
    // 데이터조차 없으면 LOGIN으로 보냄 - 확실함
    if (!auth) {
      navigate(ROUTES.LOGIN);
      return;
    }
    // 원래의 로직
    const handleRoute = () => {
      if (auth?.changePassword === true) {
        navigate(ROUTES.CHANGE_PW);
      } else if (auth?.changePassword === false) {
        navigate(ROUTES.DXP);
      }
      if (auth?.userId) {
        if (location.pathname === '/' || location.pathname === ROUTES.LOGIN) {
          navigate(ROUTES.DXP);
        }
      }
    };
    // checkAuthMutate는 한번만 - 2번째부터는 handleRoute만
    if (flag == true) {
      handleRoute();
      return;
    }
    // 첫 확인절차
    checkAuthMutate(undefined, {
      onSuccess: () => {
        setFlag(true); // 확인했음을 체크
        handleRoute();
      }, // 실패로직은 checkAuthStatement에 넣었음.
    });
  }, [auth, location.pathname]);

  return (
    <Routes>
      <Route element={<pages.Login />} path={ROUTES.LOGIN} />
      <Route path="/" element={<Navigate replace to={ROUTES.LOGIN} />} />
      <Route element={<pages.ChangePw />} path={ROUTES.CHANGE_PW} />
      {Object.keys(ROUTES).map((key) => (
        <Route element={<pages.Layout />} path={ROUTES[key]} key={key} />
      ))}
    </Routes>
  );
};

export default AppRouter;
