import axios from 'axios';
import { store } from 'store';
import { setLoading } from 'store/slices/pageSlice';
import { reissuance } from './AuthApi';

/**
 * @template [T=any]
 * @typedef APIResponse
 * @prop {T} data
 * @prop {string} message
 * @prop {number} reqTime
 * @prop {string} status
 */

/**
 * @typedef {import('axios').AxiosResponse<APIResponse>} CustomAxiosResponse
 * @typedef {{
 *  defaults: Omit<import('axios').AxiosDefaults, 'headers'> & {
 *    headers: import('axios').HeadersDefaults & {
 *      [key: string]: import('axios').AxiosHeaderValue
 *    }
 *  };
 *  interceptors: {
 *    request: import('axios').AxiosInterceptorManager<InternalAxiosRequestConfig>;
 *    response: import('axios').AxiosInterceptorManager<CustomAxiosResponse>;
 *  };
 *  getUri(config?: import('axios').AxiosRequestConfig): string;
 *  request(config: import('axios').AxiosRequestConfig): Promise<CustomAxiosResponse>;
 *  get: (url: string, config?: import('axios').AxiosRequestConfig) => Promise<CustomAxiosResponse>;
 *  delete: (url: string, config?: import('axios').AxiosRequestConfig) => Promise<CustomAxiosResponse>;
 *  head: (url: string, config?: import('axios').AxiosRequestConfig) => Promise<CustomAxiosResponse>;
 *  options: (url: string, config?: import('axios').AxiosRequestConfig) => Promise<CustomAxiosResponse>;
 *  post: (url: string, data?: any, config?: import('axios').AxiosRequestConfig) => Promise<CustomAxiosResponse>;
 *  put: (url: string, data?: any, config?: import('axios').AxiosRequestConfig) => Promise<CustomAxiosResponse>;
 *  patch: (url: string, data?: any, config?: import('axios').AxiosRequestConfig) => Promise<CustomAxiosResponse>;
 *  postForm: (url: string, data?: any, config?: import('axios').AxiosRequestConfig) => Promise<CustomAxiosResponse>;
 *  putForm: (url: string, data?: any, config?: import('axios').AxiosRequestConfig) => Promise<CustomAxiosResponse>;
 *  patchForm: (url: string, data?: any, config?: import('axios').AxiosRequestConfig) => Promise<CustomAxiosResponse>;
 * }} MyAxiosInstance
 */

/** @type {MyAxiosInstance} */
export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_DWIC_API}`,
  withCredentials: true,
});

/**
 * 다수 요청을 방지하기 위한 flag 변수
 */
let isTokenRefreshing = false;
/**
 * 토큰 재발급을 기다리고 있는 요청들 배열
 */
let refreshSubscribers = [];

/**
 *  전 API 통신시 Spin 넣기
 * */
axiosInstance.interceptors.request.use(
  (config) => {
    store.dispatch(setLoading(true));
    return config;
  },
  (error) => {
    store.dispatch(setLoading(false));
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    store.dispatch(setLoading(false));
    // 추후 B/E 메시지 추가 here
    return response;
  },
  async (error) => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;
    // 토큰 관련 에러가 아닌 상황.
    if (status !== 403) {
      store.dispatch(setLoading(false));
      // 추후 B/E 메시지 추가 here
      return Promise.reject(error);
    }

    // 토큰 재발급 요청 로직 시작

    // 재요청 생성 후 refreshSubscribers 등록
    const retryOriginalRequest = new Promise((resolve) => {
      refreshSubscribers.push(() => {
        resolve(axios(originalRequest));
      });
    });

    // 이미 재발급이 진행중이면 여기서 끝
    if (isTokenRefreshing == true) {
      store.dispatch(setLoading(false));
      return retryOriginalRequest;
    }

    // 첫 403인 경우 - 중복 재발급 방지
    isTokenRefreshing = true;
    // 토큰 재발급
    return (
      reissuance()
        // 토큰 재발급 성공
        .then(() => {
          isTokenRefreshing = false;
          let i = 0;
          let len = refreshSubscribers.length;
          // 막혀있던 모든 요청을 해소
          return new Promise((resolve) => {
            refreshSubscribers.map((callback) => {
              callback();
              i++;
              // 재시도가 끝나면 resolve
              if (i == len) resolve();
            });
          });
        })
        // refreshSubscribers 초기화
        .then(() => {
          refreshSubscribers = [];
          store.dispatch(setLoading(false));
          return retryOriginalRequest;
        })
        // reissuance 실패
        .catch(() => {
          return Promise.reject(error);
        })
    );
  },
);
