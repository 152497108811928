import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  items: [],
  selectedLnb: null,
};

const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    openTabs(state, action) {
      const newTab = {
        ...action.payload,
        key: action.payload?.menuId,
        label: action.payload?.menuNm,
      };
      const addTabList = _.unionBy([...state.items, newTab], 'key');
      state.items = addTabList;
      state.selectedLnb = action.payload?.menuId;
    },
    closeTabs(state, action) {
      state.items = state.items.filter((item) => item.key !== action.payload);
    },
    selectTabs(state, action) {
      state.selectedLnb = action.payload;
    },
    refreshTabs(state, action) {
      state.items = state.items.map((item, index) =>
        index === 0 ? { ...item, menuId: action.payload, key: action.payload } : item,
      );
    },
    resetTabs(state, action) {
      state.items = initialState.items;
      state.selectedLnb = initialState.selectedLnb;
    },
  },
});

export const { openTabs, closeTabs, selectTabs, refreshTabs, resetTabs } = tabsSlice.actions;

export default tabsSlice.reducer;
