import { Tabs as _Tabs } from 'antd';

export default function TabPanes(props) {
  const { tabItems, activeKey, onEdit, onTabClick, isNestedTab, isMobile } = props;
  return (
    <_Tabs
      className={`ant-tabs${isNestedTab ? '-nested' : ''}${isMobile ? ' isMobile' : ''}`}
      destroyInactiveTabPane={false}
      activeKey={activeKey}
      hideAdd
      onTabClick={(e) => onTabClick(e)}
      onEdit={(e) => onEdit(e)}
      type="editable-card"
      size="small"
      items={tabItems?.sort((a, b) => (a.key === 'home' ? -1 : b.key === 'home' ? 1 : 0))}
    ></_Tabs>
  );
}
