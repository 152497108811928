import { axiosInstance } from 'api/axiosConfig';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ComponentMappingByRoleService } from 'service/componentMapping';
import {
  getMenusFail,
  getMenusRequest,
  getMenusSuccess,
  getRoleCmpFail,
  getRoleCmpRequest,
  getRoleCmpSuccess,
} from '../slices/menuSlice';

const URI = `${process.env.REACT_APP_DWIC_API}/menu`;

function* handleGetMenus({ payload }) {
  try {
    const params = { userId: payload.userId };
    const response = yield call(axiosInstance.get, `${URI}/hrclist`, { params });
    const data = response?.data?.data?.result;
    yield put(getMenusSuccess(data));
  } catch (error) {
    yield put(getMenusFail());
  }
}

function* handleRoleCmp(action) {
  try {
    const params = action?.payload;
    const response = yield call(
      axiosInstance.get,
      `${URI}/cmp/user/ctrl/list?menuId=${encodeURIComponent(params.menuId)}&roleList=${params.roleList}`,
    );
    const data = response?.data?.data;
    if (!data?.length) throw Error;
    const roleData = ComponentMappingByRoleService(data);
    yield put(getRoleCmpSuccess({ menuPath: data[0].menuPath, data: roleData }));
  } catch (error) {
    yield put(getRoleCmpFail());
  }
}

export default function* menuSaga() {
  yield takeEvery(getMenusRequest.type, handleGetMenus);
  yield takeEvery(getRoleCmpRequest.type, handleRoleCmp);
}
