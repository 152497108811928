import { axiosInstance } from './axiosConfig';

const URI = `/user`;

/*  
  사용자 목록 조회
*/
export async function getUserList(params) {
  const response = await axiosInstance.get(`${URI}/list`, {
    params,
  });
  return response.data?.data;
}

/*  
  사용자 수정(단건)
*/
export async function updateUserInfo(params) {
  const response = await axiosInstance.patch(`${URI}/update`, params);
  return response.data;
}

/*  
  신규 사용자 등록(단건)
*/
export async function saveUserInfo(params) {
  const response = await axiosInstance.post(`${URI}/join`, params);
  return response.data;
}

/*  
  사용자 상태 수정(단건)
*/
export async function updateUserSttdCd(params) {
  const response = await axiosInstance.patch(`${URI}/update/sttd`, params);
  return response.data;
}

/*  
  사용자 역할 목록 조회
*/
export async function getUserRoleList(params) {
  const response = await axiosInstance.get(`${URI}/role/list`, {
    params,
  });
  return response.data?.data;
}

/*  
  사용자 역할 저장
*/
export async function saveUserRole(params) {
  const response = await axiosInstance.post(`${URI}/role`, params);
  return response.data;
}

/* 
  사용자 비밀번호 초기화
*/
export async function initUserPassword(params) {
  const response = await axiosInstance.patch(`${URI}/initpass`, params);
  return response.data;
}

/* 
  사용자별 정보 접근 권한 조회
*/
export async function getUserAccauth(params) {
  const response = await axiosInstance.get(`${URI}/accauth`, {
    params,
  });
  return response.data?.data;
}
