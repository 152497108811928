import { Spin as _Spin } from 'antd';

export default function Spin() {
  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        // width: '100%',
        // height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
      }}
    >
      <_Spin size="large" />
    </div>
  );
}
