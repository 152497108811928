import { createSlice } from '@reduxjs/toolkit';

/*
 * 페이지(탭) 이동 간 상태를 저장하고 사용하기 위한 slice.
 */

const INIT_CLAIM_FIELD_DETAIL = {};
const INIT_CLAIM_PROCESS_DETAIL = {};

const initialState = {
  loading: false,
  /* 클레임 조회 > 필드 클레임 상세조회 */
  claimFeildDetail: INIT_CLAIM_FIELD_DETAIL,
  /* 클레임 조회 > 공정 클레임 상세조회 */
  claimProcessDetail: INIT_CLAIM_PROCESS_DETAIL,
  /* VoC 목록 > VoC 상세조회 */
  vocId: '',
  /* VoC 유형 > VoC 유형 상세조회 */
  vocTmpltId: '',
  rvsnNo: 0,
  /* 개선대책 관련 */
  new: '',
  modify: '',
  confirm: '',
  apply: '',
  evaluate: '',
  detail: '',
};

/**
 * @typedef {keyof typeof initialState} KeyType
 * @typedef {{
 *     payload: {
 * key: KeyType;
 * value: any;
 * };
 *     type: string;
 * }} Action
 */

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setClaimFeildDetail(state, action) {
      state.claimFeildDetail = action.payload;
    },
    setClaimProcessDetail(state, action) {
      state.claimProcessDetail = action.payload;
    },
    /**
     * payload는 key, value로 이루어져 있고,
     *
     * initialState에 존재하는 key값에 대해 value를 지정할 수 있다.
     *
     * @param {Action} action
     *  */
    setPageState(state, action) {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const { setLoading, setClaimFeildDetail, setClaimProcessDetail, setPageState } = pageSlice.actions;

/*  */
export const claimFieldDetailStoreItem = (state) => state.page.claimFeildDetail;
export const claimProcessDetailStoreItem = (state) => state.page.claimProcessDetail;
export const vocDetailStoreItem = (state) => state.page.vocId;
export const vocTemplateStoreItem = (state) => ({
  vocTmpltId: state.page.vocTmpltId,
  rvsnNo: state.page.rvsnNo,
});
export const impStoreItem = (state) => ({
  new: state.page.new,
  modify: state.page.modify,
  confirm: state.page.confirm,
  apply: state.page.apply,
  evaluate: state.page.evaluate,
  detail: state.page.detail,
});

export default pageSlice.reducer;
