import {
  CommentOutlined,
  CustomerServiceOutlined,
  FileSearchOutlined,
  LineChartOutlined,
  ProductOutlined,
  BlockOutlined,
  ApartmentOutlined,
  SolutionOutlined,
  FileDoneOutlined,
  DesktopOutlined,
  CodeOutlined,
  UserSwitchOutlined,
  MenuOutlined,
  IssuesCloseOutlined,
  FormOutlined,
  MessageOutlined,
  FontSizeOutlined,
  FontColorsOutlined,
  SmileOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
const { Content, Sider } = Layout;

export default function LnbMenu(props) {
  const { lnbMenus, selectedGnb, selectedLnb, handleOpenTabs, children, isMobile } = props;
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const getEachItem = (label, key, icon, children, item) => {
    return {
      ...item,
      key,
      icon,
      children,
      label,
    };
  };

  const getItems = (items) => {
    const filteredItems = items?.filter((item) => item.prntsMenuId === selectedGnb);
    const prettyItems = filteredItems.map((item) => {
      let Icon;
      if (item.path.includes('community')) {
        Icon = <CommentOutlined />;
      } else if (item.path.includes('voc')) {
        Icon = <CustomerServiceOutlined />;
      } else if (item.path.includes('claimsearch')) {
        Icon = <FileSearchOutlined />;
      } else if (item.path.includes('trend')) {
        Icon = <LineChartOutlined />;
      } else if (item.path.includes('matching')) {
        Icon = <BlockOutlined />;
      } else if (item.path.includes('prodmngt')) {
        Icon = <ProductOutlined />;
      } else if (item.path.includes('qltymngt')) {
        Icon = <ApartmentOutlined />;
      } else if (item.path.includes('elecdelivery')) {
        Icon = <FileDoneOutlined />;
      } else if (item.path.includes('voctemplate')) {
        Icon = <SolutionOutlined />;
      } else if (item.path.includes('code')) {
        Icon = <CodeOutlined />;
      } else if (item.path.includes('user')) {
        Icon = <UserSwitchOutlined />;
      } else if (item.path.includes('menu')) {
        Icon = <MenuOutlined />;
      } else if (item.path.includes('message')) {
        Icon = <MessageOutlined />;
      } else if (item.path.includes('text')) {
        Icon = <FontSizeOutlined />;
      } else if (item.path.includes('auth')) {
        Icon = <FontColorsOutlined />;
      } else if (item.path.includes('role')) {
        Icon = <SmileOutlined />;
      } else {
        Icon = <FormOutlined />;
      }

      return getEachItem(item.label, item.menuId, Icon, item.children, item);
    });
    return prettyItems;
  };

  return (
    <Content>
      <Layout
        style={{
          borderRadius: borderRadiusLG,
        }}
      >
        {isMobile == false && (
          <Sider width={230} collapsible defaultCollapsed={true}>
            <Menu
              mode="inline"
              style={{
                height: '100%',
              }}
              items={getItems(lnbMenus)}
              onClick={(e) => handleOpenTabs(e.item.props)}
              selectedKeys={[selectedLnb]}
            />
          </Sider>
        )}

        <Content
          style={{
            minHeight: '79vh',
            background: '#f7f9f8',
          }}
        >
          {children}
        </Content>
      </Layout>
    </Content>
  );
}
