import { useState } from 'react';
import { EditOutlined, LogoutOutlined } from '@ant-design/icons';
import { Col, Divider, message, Row, Typography, Form } from 'antd';
import { signOutRequest } from 'api/AuthApi';
import { Button } from 'antd';
import { useSaveItems, useUpdateItems } from 'hooks/useFetchItems';
import useModal from 'hooks/useModal';
import { persistStore } from 'index';
import { useDispatch } from 'react-redux';
import { signOut } from 'store/slices/authSlice';
import { resetTabs } from 'store/slices/tabSlice';
import ChangeUser from 'pages/auth/changeuser';
import { updateUserInfo } from 'api/UserApi';
import { callChangePwRequest } from 'store/slices/authSlice';

export default function User(props) {
  const { userIdInf, avatarText } = props;
  const { showAlert, handleCloseAlert, openLargeModal, handleCloseModal } = useModal();
  const dispatch = useDispatch();
  const { mutate: signOutMutate } = useSaveItems({
    saveFunction: signOutRequest,
  });
  const [form] = Form.useForm();

  /*  
    사용자 정보수정 
  */
  const onClickEditMe = () => {
    const handleModifyParams = (modifyParams) => {
      const params = {};
      for (let key in modifyParams) {
        if (modifyParams[key]) {
          params[key] = modifyParams[key];
        }
      }

      if (!Object.keys(params).length) {
        handleCloseAlert();
        onCancel();
        return;
      }

      updateMutation.mutate(
        { ...params, userId: props?.userId },
        {
          onSuccess: () => {
            /* Alert, 모달을 닫고 로그인 화면으로 이동(=로그아웃 시키기) */
            handleCloseAlert();
            onCancel();
            dispatch(signOut());
          },
        },
      );
    };

    const contents = <ChangeUser modifyForm={form} handleModifyParams={handleModifyParams} />;
    const onCancel = () => {
      form.resetFields();
      handleCloseModal();
    };
    const onModify = () => form.submit();

    const modalParams = {
      title: '내 정보 수정',
      children: contents,
      onCancel,
      footer: (
        <>
          <Button
            type="primary"
            onClick={
              () => onModify()
              // showAlert({
              //   title: '사용자 정보 수정',
              //   message: '수정하시겠습니까?',
              //   confirm: (
              //     <Button type="primary" onClick={() => onModify()}>
              //       확인
              //     </Button>
              //   ),
              // });
            }
          >
            수정
          </Button>
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            닫기
          </Button>
        </>
      ),
    };
    openLargeModal(modalParams);
  };
  const updateMutation = useUpdateItems({
    updateFunction: (payload) => {
      updateUserInfo(payload);
    },
  });

  /* 비밀번호 변경 */
  const onCallChangePw = () => {
    handleCloseModal();
    dispatch(callChangePwRequest({ changePassword: true }));
  };

  /* 
    로그아웃 성공시 Reset되어야 하는 정보
    1. 토큰값
    2. 오픈된 탭
    3. etc.. 
  */
  const onClickLogOut = () => {
    showAlert({
      title: '로그아웃',
      message: '로그아웃 하시겠습니까?',
      confirm: (
        <Button
          type="primary"
          onClick={() => {
            signOutMutate(undefined, {
              onSuccess: () => {
                dispatch(signOut());
                dispatch(resetTabs());
                persistStore.purge();
              },
              onError: () => {
                dispatch(signOut());
                dispatch(resetTabs());
                persistStore.purge();
              },
            });
            handleCloseAlert();
          }}
        >
          확인
        </Button>
      ),
    });
  };

  return (
    <Row justify="center" align="middle" style={{ textAlign: 'center' }}>
      <Col span={24}>
        <div>
          <Typography.Text style={{ marginBottom: '8px' }}>{userIdInf}</Typography.Text>
          <Col>{avatarText}</Col>
        </div>
      </Col>
      <Divider style={{ margin: '12px 0' }} />
      {/* <Col>
        <Button icon={<EditOutlined />} onClick={() => onClickEditMe()}>
          정보수정
        </Button>
      </Col> */}
      <Col>
        <Button /* type="link" style={{ float: 'inherit' }} */ onClick={() => onCallChangePw()}>비밀번호 변경</Button>
      </Col>
      <Col>
        <Button icon={<LogoutOutlined />} onClick={() => onClickLogOut()}>
          로그아웃
        </Button>
      </Col>
    </Row>
  );
}
