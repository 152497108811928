import Title from '../Title';

export default function NotOpenPage(props) {
  return (
    <div>
      <Title {...props} />
      <h2
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh',
        }}
      >
        오픈 예정 화면입니다.
      </h2>
    </div>
  );
}
