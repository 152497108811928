import { useEffect, useState } from 'react';
import { Form, Row, Col, Card, Space } from 'antd';
import { Button } from 'antd';
import { Input } from 'antd';
import { useDispatch } from 'react-redux';
// import { register } from 'store/actions/authActions';
const { Password } = Input;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

export function AccountCheck(props) {
  const { checkAccount } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = async (e) => {
    const payload = form.getFieldsValue();
    checkAccount(payload);
  };

  return (
    <Col span={6}>
      <Card style={{ padding: 24 }}>
        <h2 style={{ marginBottom: 40 }}>CHECK ACCOUNT</h2>
        <Form
          // {...formItemLayout}
          name="email-input-form"
          // layout="inline"
          initialValues={{}}
          form={form}
          onFinish={() => onFinish()}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
          // scrollToFirstError
        >
          <Space>
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Space>
          <Form.Item style={{ marginBottom: 12 }}>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Confirm
            </Button>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            {/* <Button text="Return" style={{ width: '100%' }} onClick={() => dispatch(register({ register: false }))} /> */}
          </Form.Item>
        </Form>
      </Card>
    </Col>
  );
}

export function Register(props) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = (e) => {
    const payload = form.getFieldsValue();
  };

  return (
    <Col span={8}>
      <Card>
        <h2 style={{ marginBottom: 40 }}>SIGN UP</h2>
        <Form
          // {...formItemLayout}
          name="signup-form"
          // layout="inline"
          initialValues={{}}
          form={form}
          onFinish={() => onFinish()}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
          scrollToFirstError
        >
          <Space>
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="sendCdoe">
              <Button type="link">인증번호전송</Button>
            </Form.Item>
          </Space>
          <Space>
            <Form.Item
              name="authentication"
              label="인증번호"
              rules={[
                {
                  required: true,
                  message: 'Please input your authentication Code!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="confirmCode">
              <Button>인증번호확인</Button>
            </Form.Item>
          </Space>
          <Form.Item
            label="Password"
            name="password"
            style={{ marginBottom: 12 }}
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Password onChange={(e) => form.setFieldValue('password', e.target.value)} />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirm"
            dependencies={['password']}
            style={{ marginBottom: 12 }}
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The new password that you entered do not match!'));
                },
              }),
            ]}
          >
            <Password />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              SIGN UP
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="link"
              style={{ width: '100%' }}
              // onClick={() => dispatch(register({ register: false }))}
            >
              Return
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Col>
  );
}

export default function Signup(props) {
  const [isAccount, setIsAccount] = useState(false);

  const checkAccount = async (params) => {
    console.log('사용자 계정 체크 진행');
    // 계정이 존재하면 회원가입 화면으로
    setIsAccount(true);
    // 계정이 존재하지 않으면 로그인 화면으로
  };

  return (
    <Row align="middle" justify="space-around" style={{ height: '100vh' }}>
      {!isAccount && <AccountCheck checkAccount={checkAccount} />}
      {isAccount && <Register />}
    </Row>
  );
}
