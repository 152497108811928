import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';

/**
 * refetch를 false로 주면, 한 번 조회된 내용에 대해 캐시만을 사용하게 된다.
 * commonCode등 변하지 않는 내용에 한해서 사용하면 재요청을 줄일 수 있고,
 * 이 역시 gcTime 및 staleTime만 Infinity인 것이고
 * refetch 요청이나 invalidateQueries되면 어김없이 재요청된다.
 *
 * @param {{
 * key: string[];
 * getFunction: (req: any) => Promise<any>;
 * enabled: boolean;
 * initialData: any;
 * refetch: boolean;
 * }} props
 */
export const useGetItems = (props) => {
  const { key, getFunction, enabled = true, initialData, select, refetch = true } = props;
  const query = useQuery({
    queryKey: key,
    queryFn: getFunction,
    enabled: !!enabled,
    initialData: initialData ?? undefined,
    select,
    gcTime: refetch === false ? Infinity : 5 * 60 * 1000,
    staleTime: refetch === false ? Infinity : 0,
  });
  return query;
};

/**
 * refetch를 false로 주면, 한 번 조회된 내용에 대해 캐시만을 사용하게 된다.
 * commonCode등 변하지 않는 내용에 한해서 사용하면 재요청을 줄일 수 있고,
 * 이 역시 gcTime 및 staleTime만 Infinity인 것이고
 * refetch 요청이나 invalidateQueries되면 어김없이 재요청된다.
 *
 * @param {{
 * key: string[];
 * getFunction: (req: any) => Promise<any>;
 * enabled: boolean;
 * initialData: any;
 * }} props
 */
export const useGetItemsWithSuspense = (props) => {
  const { key, getFunction, enabled = true, initialData, select, refetch = true } = props;
  const query = useSuspenseQuery({
    queryKey: key,
    queryFn: getFunction,
    enabled: !!enabled,
    initialData: initialData ?? undefined,
    select,
    gcTime: refetch === false ? Infinity : 5 * 60 * 1000,
    staleTime: refetch === false ? Infinity : 0,
  });
  return query;
};

/**
 * @param {{
 * saveFunction: (req: any) => import('api/axiosConfig').APIResponse;
 * }} props
 */
export const useSaveItems = (props) => {
  const { saveFunction } = props;
  /** @type {import('@tanstack/react-query').UseMutationResult<import('api/axiosConfig').APIResponse>} */
  const mutation = useMutation({
    mutationFn: saveFunction,
  });
  return mutation;
};

/**
 * @param {{
 * updateFunction: (req: any) => import('api/axiosConfig').APIResponse;
 * }} props
 */
export const useUpdateItems = (props) => {
  const { updateFunction } = props;
  /** @type {import('@tanstack/react-query').UseMutationResult<import('api/axiosConfig').APIResponse>} */
  const mutation = useMutation({
    mutationFn: updateFunction,
  });
  return mutation;
};

/**
 * @param {{
 * deleteFunction: (req: any) => import('api/axiosConfig').APIResponse<any>;
 * }} props
 */
export const useDeleteItems = (props) => {
  const { deleteFunction } = props;
  /** @type {import('@tanstack/react-query').UseMutationResult<import('api/axiosConfig').APIResponse>} */
  const mutation = useMutation({
    mutationFn: deleteFunction,
  });
  return mutation;
};
