import React from 'react';
import AppRouter from 'router/AppRouter';
import './assets/index';
import './App.css';
import { useSelector } from 'react-redux';
import Spin from 'components/atoms/Spin';

function App() {
  const loading = useSelector((state) => state.page.loading);

  return (
    <div>
      <AppRouter />
      {loading && <Spin />}
    </div>
  );
}

export default App;
