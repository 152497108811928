import { combineReducers } from 'redux';
import authReducer from '../slices/authSlice'; // Updated to use the new auth slice
import menuReducer from '../slices/menuSlice';
import modalReducer from '../slices/modalSlice';
import tabReducer from '../slices/tabSlice';
import pageReducer from '../slices/pageSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  menus: menuReducer,
  modal: modalReducer,
  tabs: tabReducer,
  page: pageReducer,
});

export default rootReducer;
