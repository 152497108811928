import { Button } from 'antd';

/* ANALYSIS 컴포넌트 */
export const Components = {
  /** 공통 */
  // MEUI_00036, MEUI_00037
  REVISION_BUTTON: (props) => <Button {...props}>개정</Button>,
  /** MEUI_00031 - 개선대책 조회 */
  CREATE_MEASURE_BUTTON: (props) => <Button {...props}>신규</Button>,
  /** MEUI_00034 - 개선대책 신규: 권한 요구하는 버튼 없음 */
  /** MEUI_00035 - 개선대책수립 조회 및 확정처리 */
  MODIFY_BUTTON: (props) => <Button {...props}>수정</Button>,
  DELETE_BUTTON: (props) => <Button {...props}>삭제</Button>,
  CONFIRM_MEASURE_BUTTON: (props) => <Button {...props}>대책확정</Button>,
  /** MEUI_00036 - 개선대책적용 */
  APPLY_MEASURE_BUTTON: (props) => <Button {...props}>대책적용</Button>,
  /** MEUI_00037 - 개선대책 유효성 평가 */
  EVALUTE_VALID_BUTTON: (props) => <Button {...props}>유효성평가</Button>,
  /** MEUI_00038 - 개선대책 평가완료 상세조회: 권한 요구하는 버튼 없음 */
  /** MEUI_00039 - 개선대책 수정: 권한 요구하는 버튼 없음 */
};

export default Components;
