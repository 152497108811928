import { Card, Col, Form, Row } from 'antd';
import { Button } from 'antd';
import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import { callChangePwRequest, changePwRequest } from 'store/slices/authSlice';
import { eMailTypeValid, requiredValid } from 'service/formValid';
import { passwordValid } from 'service/formValid';

const { Password } = Input;

export default function ChangePw(props) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = (e) => {
    const payload = form.getFieldsValue();
    dispatch(changePwRequest(payload));
  };

  const onBackToLogin = () => {
    dispatch(callChangePwRequest({ changePassword: false }));
  };

  return (
    <Row align="middle" justify="space-around" style={{ height: '100vh' }}>
      <Col span={6}>
        <Card style={{ padding: 24 }}>
          <h2 style={{ marginBottom: 40 }}>비밀번호 변경</h2>

          <Form
            name="basic"
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            form={form}
            onFinish={() => onFinish()}
            autoComplete="off"
          >
            <Form.Item label="E-mail" name="account" rules={[eMailTypeValid(), requiredValid('E-mail')]}>
              <Input onChange={(e) => form.setFieldValue('account', e.target.value)} />
            </Form.Item>

            <Form.Item
              label="기존 비밀번호"
              name="password"
              style={{ marginBottom: 12 }}
              rules={[requiredValid('기존 비밀번호'), { validator: passwordValid }]}
            >
              <Password onChange={(e) => form.setFieldValue('password', e.target.value)} />
            </Form.Item>

            <Form.Item
              label="새 비밀번호"
              name="newpassword"
              style={{ marginBottom: 12 }}
              rules={[requiredValid('새 비밀번호'), { validator: passwordValid }]}
            >
              <Password onChange={(e) => form.setFieldValue('newpassword', e.target.value)} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                변경
              </Button>
            </Form.Item>
          </Form>
          <div>
            <Button type="link" style={{ float: 'right' }} onClick={() => onBackToLogin()}>
              돌아가기
            </Button>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
