import { Row, Col, Divider, message } from 'antd';
import useTabs from 'hooks/useTabs';

export default function Tab(props) {
  const { children: tabItems } = props;
  const { activeKey, activeTabs } = tabItems;
  const { handleCloseTabs, handleOpenTabs, handleRefreshTabs } = useTabs();

  const onCloseCurrent = () => {
    if (activeKey === 'home') return;
    const currentIdx = activeTabs.findIndex((item) => item.menuId === activeKey);
    const leftIdx = currentIdx - 1;
    const leftTab = activeTabs[leftIdx];
    handleCloseTabs(activeKey);
    setTimeout(() => {
      handleOpenTabs(leftTab);
    }, 1);
  };

  const onCloseOthers = () => {
    const closableTabs = activeTabs
      .filter((item) => item.menuId !== 'home')
      .filter((item) => item.menuId !== activeKey)
      .map((item) => item.menuId);
    for (let id of closableTabs) {
      handleCloseTabs(id);
    }
  };

  const onRefreshTab = () => {
    message.info('오픈예정입니다.');
    // const refresKey = activeKey + 'refresh';
    // Components[refresKey] = Components[activeKey];
    // const refreshTab = activeTabs?.find((item) => `${item.key}refresh` === refresKey);
    // handleRefreshTabs(refresKey);
    // setTimeout(() => {
    //   handleOpenTabs(refreshTab);
    // }, 1);
  };

  return (
    <Row justify="center" align="middle" style={{ textAlign: 'center' }}>
      <Col span={24}>
        <div style={{ cursor: 'pointer' }} onClick={() => onCloseCurrent()}>
          닫기
        </div>
      </Col>
      <Col span={24}>
        <div style={{ cursor: 'pointer' }} onClick={() => onCloseOthers()}>
          다른 탭 닫기
        </div>
      </Col>
      {/* <Divider style={{ margin: '12px 0' }} />
      <Col span={24}>
        <div style={{ cursor: 'pointer' }} onClick={() => onRefreshTab()}>
          새로고침
        </div>
      </Col> */}
    </Row>
  );
}
