/* 메뉴 렌더링을 위해, children 뎁스에 상관없이 빈 배열이면 children 키 삭제하기 */
export const removeEmptyChildrenService = (arr) => {
  const newArr = [...arr];

  newArr.forEach((item) => {
    // eslint-disable-next-line no-prototype-builtins
    if (Array.isArray(item?.children)) {
      if (item.children.length === 0) {
        // Remove the 'children' key if it's an empty array
        delete item.children;
      } else {
        // Recursively call the function on the 'children' array
        removeEmptyChildrenService(item.children);
      }
    }
  });

  return newArr;
};

/* children 상관없이 전체 메뉴 리스트 뽑아내기 */
export const flatMenuService = (nestedMenus) => {
  const result = [];
  const flatten = (items) => {
    items.forEach((item) => {
      result.push(item);
      if (item.children) {
        flatten(item.children);
      }
    });
  };
  flatten(nestedMenus);
  return result;
};

/* children 뎁스에 상관없이 key, label 키 세팅 */
export const setKeyAndLabel = (arr) => {
  const newArr = [...arr];

  arr.forEach((item) => {
    // Set key and label based on existing properties
    item.key = item.menuId;
    item.label = item.menuNm;

    // If the item has children, recursively call the function
    if (Array.isArray(item.children)) {
      setKeyAndLabel(item.children);
    }
  });

  return newArr;
};

/* 계층적 메뉴 만들기 */
export const hierarchyMenuService = (flatData) => {
  // Create a map of menu items by their menuId
  const menuMap = {};
  flatData.forEach((item) => {
    menuMap[item.menuId] = { ...item, children: [] };
  });

  // Create the hierarchical structure
  const hierarchy = [];
  flatData.forEach((item) => {
    if (item.prntsMenuId) {
      // If the item has a parent, add it to the parent's children
      menuMap[item.prntsMenuId].children.push(menuMap[item.menuId]);
    } else {
      // If the item does not have a parent, it is a top-level item
      hierarchy.push(menuMap[item.menuId]);
    }
  });

  return hierarchy;
};
