import { Avatar, Button, Card, Col, Form, Input, Row, Select } from 'antd';
import useModal from 'hooks/useModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  eMailTypeValid,
  englishOnlyValid,
  numberHyphenValid,
  numberOnlyValid,
  phoneNumberLenValid,
  telNumberLenValid,
} from 'service/formValid';
import { callChangePwRequest } from 'store/slices/authSlice';

const { Option } = Select;

export function UpdateForm(props) {
  const { modifyForm, handleModifyParams } = props;
  const form = modifyForm;

  const onSubmit = () => {
    return handleModifyParams(form.getFieldsValue());
  };

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item name="emailAddr" label="E-mail" rules={[eMailTypeValid()]}>
        <Input />
      </Form.Item>

      <Form.Item name="deptNm" label="부서">
        <Input />
      </Form.Item>

      <Form.Item name="userEngNm" label="영문성함" rules={[{ validator: englishOnlyValid }]}>
        <Input />
      </Form.Item>

      <Form.Item
        name="mblphnNo"
        label="개인 연락처"
        rules={[{ validator: numberHyphenValid }, { validator: numberOnlyValid }, { validator: phoneNumberLenValid }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="telNo"
        label="회사 연락처"
        rules={[{ validator: numberHyphenValid }, { validator: numberOnlyValid }, { validator: telNumberLenValid }]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
}

export function CurrentUserInfoCart(props) {
  const dispatch = useDispatch();
  const { handleCloseModal } = useModal();

  const onCallChangePw = () => {
    handleCloseModal();
    dispatch(callChangePwRequest({ changePassword: true }));
  };

  return (
    <Card bordered={true} style={{ float: 'left' }}>
      <Avatar size={60}>{props?.userNm[0]}</Avatar>
      <div style={{ textAlign: 'left' }}>
        <p>
          <strong>성함</strong> : {props?.userNm}
        </p>
        <p>
          <strong>회사</strong> : {props?.coNm}
        </p>
        <p>
          <strong>부서</strong> : {props?.deptNm}
        </p>
        <p>
          <strong>직책</strong> : {props?.jposNm}
        </p>
      </div>
      <div>
        <Button type="link" style={{ float: 'inherit' }} onClick={() => onCallChangePw()}>
          비밀번호 변경
        </Button>
      </div>
    </Card>
  );
}

export default function ChangeUser(props) {
  const { user } = useSelector((state) => ({
    user: state?.auth,
  }));
  const { form, handleModifyParams } = props;
  return (
    <div style={{ maxWidth: '1000px', margin: '0 auto', padding: '20px' }}>
      <Row gutter={16} justify="start" align="middle">
        <Col span={8} style={{ textAlign: 'center' }}>
          <CurrentUserInfoCart {...user} />
        </Col>
        <Col span={16}>
          <UpdateForm {...user} form={form} handleModifyParams={handleModifyParams} />
        </Col>
      </Row>
    </div>
  );
}
