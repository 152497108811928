import { useDispatch } from 'react-redux';
import {
  closeModal,
  openModal,
  openAllCollapse,
  closeAllCollapse,
  openAlert,
  closeAlert,
} from 'store/slices/modalSlice';
import { Row, Col } from 'antd';
import { Button } from 'antd';
import { ModalTitle, ModalTileButton } from 'components/molecules/Title';
import { ExclamationCircleOutlined } from '@ant-design/icons';

// dispatch at here
export default () => {
  const dispatch = useDispatch();

  const handleOpenModal = (params) => {
    const payload = { ...params, open: true };
    dispatch(openModal(payload));
  };

  const handleCloseModal = () => {
    const payload = { open: false };
    dispatch(closeModal(payload));
  };

  const handleOpenAlert = (params) => {
    const payload = { ...params, open: true };
    dispatch(openAlert(payload));
  };

  const handleCloseAlert = () => {
    const payload = { open: false };
    dispatch(closeAlert(payload));
  };

  const handleModalCollpase = (params) => {
    if (params) {
      dispatch(openAllCollapse(params));
    } else {
      dispatch(closeAllCollapse(params));
    }
  };

  const hanldeModalTitle = (props) => {
    const { title, titleButton } = props;

    return titleButton ? <ModalTileButton title={title}>{titleButton}</ModalTileButton> : <ModalTitle title={title} />;
  };

  const openXsmallModal = (params) => {
    const payload = { ...params, open: true, xs: true, title: hanldeModalTitle(params) };
    dispatch(openModal(payload));
  };

  const openSmallModal = (params) => {
    const payload = { ...params, open: true, sm: true, title: hanldeModalTitle(params) };
    dispatch(openModal(payload));
  };

  const openMediumModal = (params) => {
    const payload = { ...params, open: true, md: true, title: hanldeModalTitle(params) };
    dispatch(openModal(payload));
  };

  const openLargeModal = (params) => {
    const payload = { ...params, open: true, lg: true, title: hanldeModalTitle(params) };
    dispatch(openModal(payload));
  };

  const openXlargeModal = (params) => {
    const payload = { ...params, open: true, xl: true, title: hanldeModalTitle(params) };
    dispatch(openModal(payload));
  };

  const openXXlargeModal = (params) => {
    const payload = { ...params, open: true, xxl: true, title: hanldeModalTitle(params) };
    dispatch(openModal(payload));
  };

  const showAlert = ({ title, message, confirm }) => {
    const footer = confirm ? (
      <>
        <Button
          onClick={() => {
            handleCloseAlert();
          }}
        >
          닫기
        </Button>
        {confirm}
      </>
    ) : (
      <Button
        onClick={() => {
          handleCloseAlert();
        }}
      >
        닫기
      </Button>
    );

    const payload = {
      open: true,
      xs: true,
      title: (
        <Row align="middle" className="titleWrapper">
          <Col span={24}>
            <div className="menuTitle">
              <span>
                <ExclamationCircleOutlined />
              </span>
              <h3 style={{ paddingLeft: 2 }}>{title}</h3>
            </div>
          </Col>
        </Row>
      ),
      children: <span>{message}</span>,
      footer,
      // onCancel: () => handleCloseAlert(),
    };
    handleOpenAlert(payload);
  };

  return {
    handleOpenModal,
    handleCloseModal,
    handleModalCollpase,
    openXsmallModal,
    openSmallModal,
    openMediumModal,
    openLargeModal,
    openXlargeModal,
    openXXlargeModal,
    showAlert,
    handleCloseAlert,
  };
};
