import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';

/* ADMIN 컴포넌트 */
export const Components = {
  /* 회사 및 사용자 관리  */
  CREATE_COMPANY_BUTTON: (props) => <PlusOutlined {...props} style={{ marginLeft: 8, color: '#009A93' }} />,
  CREATE_COMPANY_ROLE_BUTTON: (props) => <Button {...props}>{props.text}</Button>,
  CREATE_USER_ROLE_BUTTON: (props) => <Button {...props}>{props.text}</Button>,
};

export default Components;
