/* AUTH */
export const LOGIN = '/login';
export const CHANGE_PW = '/chnagepw';
export const RESET_PW = '/resetpw';
export const SIGN_UP = '/signup';
/* HOME */
export const DXP = '/dxp';
/* COMMON */
export const ANNOUNCEMENT = `${DXP}/announcement`;
export const FAQ = `${DXP}/faq`;
export const QNA = `${DXP}/qna`;
export const QNADETAIL = `${DXP}/qnadetail`;
export const GUIDE = `${DXP}/guide`;
export const VOC = `${DXP}/voc`;
/* Analysis */
export const totalsearch = `${DXP}/totalsearch`;
export const process = `${DXP}/process`;
export const field = `${DXP}/field`;
export const improvemsr = `${DXP}/improvemsr`;
export const badtotal = `${DXP}/badtotal`;
export const qltysummary = `${DXP}/qltysummary`;
export const partqlty = `${DXP}/partqlty`;
export const worstitem = `${DXP}/worstitem`;
export const ppmsearch = `${DXP}/ppmsearch`;
export const perfsearch = `${DXP}/perfsearch`;
export const badtransition = `${DXP}/badtransition`;
/* AI/MLOps */
export const virtualasbly = `${DXP}/virtualasbly`;
export const ohgframe = `${DXP}/ohgframe`;
/* sMES */
export const prodmngt = `${DXP}/prodmngt`;
export const qltymngt = `${DXP}/qltymngt`;
export const elecdelivery = `${DXP}/elecdelivery`;
/* ADMIN */
export const voctemplate = `${DXP}/voctemplate`;
/* DEV */
export const terms = `${DXP}/terms`;
export const unity = `${DXP}/unity`;
export const qrcode = `${DXP}/qrcode`;
export const mastshimming = `${DXP}/mastshimming`;
export const mobilemain = `${DXP}/mobilemain`;
export const components = `${DXP}/components`;
export const newpagetest = `${DXP}/newpagetest`;
