import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import rootReducer from './reducers';
import rootSaga from './sagas/rootSaga';

const sagaMiddleware = createSagaMiddleware();
const l = createLogger({
  predicate: (_, action) =>
    !['page/setLoading', 'persist/PERSIST', '&_INIT_MESSAGE_LISTENER', 'persist/REHYDRATE'].includes(action.type),
});
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const stateSyncMiddleware = createStateSyncMiddleware({
  broadcastChannelOption: {
    name: 'dxp-sync',
  },
  predicate: (action) => action.type.startsWith('auth'),
});

const middlewares = [sagaMiddleware, stateSyncMiddleware];

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(l);
}

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV !== 'production',
});

const persistor = persistStore(store);
initMessageListener(store);
sagaMiddleware.run(rootSaga);

export { persistor, store };
