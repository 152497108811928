import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';

/* COMMON 컴포넌트 */
export const Components = {
  CREATE_BUTTON: (props) => <Button {...props}>{props.text}</Button>,
  MODIFY_BUTTON: (props) => <Button {...props}>{props.text}</Button>,
  DELETE_BUTTON: (props) => <Button {...props}>{props.text}</Button>,
  COMMENT_CREATE_BUTTON: (props) => <Button {...props}>{props.text}</Button>,
  COMMENT_MODIFY_BUTTON: (props) => <Button {...props}>{props.text}</Button>,
  COMMENT_DELETE_BUTTON: (props) => <CloseOutlined {...props} />,
};

export default Components;
