import { withSignOutOnError } from 'service/utils';
import { axiosInstance } from './axiosConfig';

export const signOutRequest = async () => {
  const response = await axiosInstance.post('/auth/logout');
  return response.data;
};

export const reissuance = withSignOutOnError(async () => {
  const response = await axiosInstance.post('/auth/reissuance');
  return response;
});
let flag = false;
export const checkAuthStatement = withSignOutOnError(async () => {
  if (flag == true) return {};
  // 다중 요청 방지
  flag = true;
  const response = await axiosInstance.post('/auth/statement');
  return response;
});
