import { signOutRequest } from 'api/AuthApi';
import { persistStore } from 'index';
import { store } from 'store';
import { signOut } from 'store/slices/authSlice';
import { resetTabs } from 'store/slices/tabSlice';
/** ms 단위로 진행을 지연하고 promise를 반환함 */
export const delay = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/** 인자로 받은 비동기 함수를 실행하며 실패 시 로그아웃을 진행하는 HOF */
export const withSignOutOnError = (promiseFn) => async () => {
  try {
    await promiseFn();
  } catch (err) {
    await signOutRequest()
      .catch(() => {
        // 에러 전파 방지
      })
      .finally(() => {
        store.dispatch(signOut());
        store.dispatch(resetTabs());
      });
    await persistStore.purge();
    return err;
  }
};
