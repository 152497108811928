import { Modal as _Modal } from 'antd';
import { useSelector } from 'react-redux';

export function Modal(props) {
  const { xs, sm, md, lg, xl, xxl, ...rest } = props;

  return (
    <>
      {xs && <XsmallModal {...rest} />}
      {sm && <SmallModal {...rest} />}
      {md && <MediumModal {...rest} />}
      {lg && <LargeModal {...rest} />}
      {xl && <XlargeModal {...rest} />}
      {xxl && <XXlargeModal {...rest} />}
      {!xs && !sm && !md && !lg && !xl && !xxl && <RestModal {...rest} />}
    </>
  );
}

export function Alert(props) {
  const { xs, sm, md, lg, xl, xxl, ...rest } = props;

  return (
    <>
      {xs && <XsmallModal {...rest} />}
      {sm && <SmallModal {...rest} />}
      {md && <MediumModal {...rest} />}
      {lg && <LargeModal {...rest} />}
      {xl && <XlargeModal {...rest} />}
      {xxl && <XXlargeModal {...rest} />}
    </>
  );
}

export function RestModal(props) {
  const { className, open, onOk, onCancel, title, children, maskClosable = true, footer, style, width } = props;

  return (
    <_Modal
      className={className}
      title={title}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      maskClosable={maskClosable}
      footer={footer}
      width={width}
      style={style}
    >
      {children}
    </_Modal>
  );
}

export function XsmallModal(props) {
  const { className, open, onOk, onCancel, title, children, maskClosable = true, footer, style } = props;

  return (
    <_Modal
      className="xs-antd-modal"
      title={title}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      maskClosable={maskClosable}
      footer={footer}
      width={400}
      style={style}
    >
      {children}
    </_Modal>
  );
}

export function SmallModal(props) {
  const { className, open, onOk, onCancel, title, children, maskClosable = true, footer, style } = props;

  return (
    <_Modal
      className="sm-antd-modal"
      title={title}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      maskClosable={maskClosable}
      footer={footer}
      width={600}
      style={style}
    >
      {children}
    </_Modal>
  );
}

export function MediumModal(props) {
  const { className, open, onOk, onCancel, title, children, maskClosable = true, footer, style } = props;

  return (
    <_Modal
      className="md-antd-modal"
      title={title}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      maskClosable={maskClosable}
      footer={footer}
      width={800}
      style={style}
    >
      {children}
    </_Modal>
  );
}

export function LargeModal(props) {
  const { className, open, onOk, onCancel, title, children, maskClosable = true, footer, style } = props;

  return (
    <_Modal
      className="lg-antd-modal"
      title={title}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      maskClosable={maskClosable}
      footer={footer}
      width={1200}
      style={style}
    >
      {children}
    </_Modal>
  );
}

export function XlargeModal(props) {
  const { className, open, onOk, onCancel, title, children, maskClosable = true, footer, style } = props;

  return (
    <_Modal
      className="xl-antd-modal"
      title={title}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      maskClosable={maskClosable}
      footer={footer}
      width={1600}
      style={style}
    >
      {children}
    </_Modal>
  );
}

export function XXlargeModal(props) {
  const { className, open, onOk, onCancel, title, children, maskClosable = true, footer, style } = props;

  return (
    <_Modal
      className="xxl-antd-modal"
      title={title}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      maskClosable={maskClosable}
      footer={footer}
      width={1800}
      style={style}
    >
      {children}
    </_Modal>
  );
}
