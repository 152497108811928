/* 필수 입력 Valid */
export const requiredValid = (label) => {
  return {
    required: true,
    message: `${label}을 입력해주시기 바랍니다.`,
  };
};

/* 이메일 형식 Valid */
export const eMailTypeValid = () => {
  return {
    type: 'email',
    message: 'E-mail 형식으로 입력해주시기 바랍니다.',
  };
};

/* 영문 입력 Valid */
export const englishOnlyValid = (_, value) => {
  const englishOnlyRegex = /^[A-Za-z]+$/;
  if (!value) {
    return Promise.resolve();
  }
  if (!englishOnlyRegex.test(value)) {
    return Promise.reject(new Error('영어 문자만 입력할 수 있습니다.'));
  }
  return Promise.resolve();
};
/* 
 '/'로만 시작
 영문 소문자 입력 Valid 
*/
export const slashLowEnglisNumberhOnlyValid = (_, value) => {
  const pathRegex = /^\/[a-z0-9]+$/;
  if (!value) {
    return Promise.resolve();
  }
  if (!pathRegex.test(value)) {
    return Promise.reject(new Error('경로는 슬래시로 시작하고 영어 소문자와 숫자만 포함할 수 있습니다.'));
  }
  return Promise.resolve();
};

/* 비밀번호 형식 Valid */
export const passwordValid = (_, value) => {
  if (value.length < 8 || value.length > 48) {
    return Promise.reject(new Error('비밀번호는 8자 이상 48자 이하로 입력해주시기 바랍니다.'));
  }
  if (!/[A-Za-z]/.test(value)) {
    return Promise.reject(new Error('비밀번호에는 최소 하나의 영어 문자가 포함되어야 합니다.'));
  }
  if (!/[0-9!@#$%^&*(),.?":{}|<>]/.test(value)) {
    return Promise.reject(new Error('비밀번호에는 최소 하나의 숫자나 특수 문자가 포함되어야 합니다.'));
  }
  return Promise.resolve();
};

/* 휴대폰,전화번호 Valid */
// 1. 하이폰 제거
export const numberHyphenValid = (_, value) => {
  if (value && value.includes('-')) {
    return Promise.reject(new Error('"-"를 포함할 수 없습니다.'));
  }
  return Promise.resolve();
};
// 2. 숫자만 입력
export const numberOnlyValid = (_, value) => {
  if (value && isNaN(value)) {
    return Promise.reject(new Error('숫자만 입력해주시기 바랍니다.'));
  }
  return Promise.resolve();
};
// 3. 자리수
// 1) 휴대폰은 10~11자리
export const phoneNumberLenValid = (_, value) => {
  if (value && (value.length < 10 || value.length > 11)) {
    return Promise.reject(new Error('10자리 ~ 11자리 숫자를 입력해주시기 바랍니다.'));
  }
  return Promise.resolve();
};
// 2) 전화번호는 9~11자리
export const telNumberLenValid = (_, value) => {
  if (value && (value.length < 9 || value.length > 11)) {
    return Promise.reject(new Error('9자리 ~ 11자리 숫자를 입력해주시기 바랍니다.'));
  }
  return Promise.resolve();
};
// 3) 5자리 (ex 우편번호)
export const fiveNumberLenValid = (_, value) => {
  if (value && (value.length < 5 || value.length > 5)) {
    return Promise.reject(new Error('5자리 숫자를 입력해주시기 바랍니다.'));
  }
  return Promise.resolve();
};
// 4) 10자리 (ex 사업자번호)
export const tenNumberLenValid = (_, value) => {
  if (value && (value.length < 10 || value.length > 10)) {
    return Promise.reject(new Error('10자리 숫자를 입력해주시기 바랍니다.'));
  }
  return Promise.resolve();
};
// 5) 1 ~ 50자리 (ex 회사명)
export const companyLenValid = (_, value) => {
  if (value && (value.length < 1 || value.length > 50)) {
    return Promise.reject(new Error('1~50 자리 숫자를 입력해주시기 바랍니다.'));
  }
  return Promise.resolve();
};
