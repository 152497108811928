import { useDispatch, useSelector } from 'react-redux';
import { closeTabs, openTabs, refreshTabs, selectTabs } from 'store/slices/tabSlice';

export default () => {
  const dispatch = useDispatch();
  const { tabs } = useSelector((state) => ({
    tabs: state?.tabs?.items,
  }));

  const handleOpenTabs = (params) => {
    dispatch(openTabs(params));
  };

  const handleCloseTabs = (params) => {
    dispatch(closeTabs(params));
    /* 탭 닫은 후 가장 최근에 열린 탭 열기 */
    const newTabs = tabs.filter((item) => item.key !== params);
    const newTabParams = newTabs[newTabs.length - 1];
    handleOpenTabs(newTabParams);
  };

  const handleSelectTabs = (params) => {
    dispatch(selectTabs(params));
  };

  const handleOpenHomeTab = () => {
    const params = {
      menuId: 'home',
      menuNm: 'Home',
      prntsMenuId: '',
      prntsMenuNm: '',
      path: '/home',
      componentNm: 'Home',
      pageNm: 'HOME',
    };
    handleOpenTabs(params);
  };

  const handleRefreshTabs = (params) => {
    dispatch(refreshTabs(params));
  };

  return { handleOpenTabs, handleCloseTabs, handleSelectTabs, handleOpenHomeTab, handleRefreshTabs, tabs };
};
