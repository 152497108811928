import { BellOutlined, ExclamationCircleOutlined, MenuOutlined } from '@ant-design/icons';
import { Avatar, Col, Flex, Layout, Menu, Row, Space, Typography, Button } from 'antd';
import { Select, Popover, Switch } from 'antd';
import mobileMenuIdArr from 'constants/mobileMenus';
import useModal from 'hooks/useModal';
import useTabs from 'hooks/useTabs';
import UserPopoverContent from 'templates/popover/UserPopoverContent';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { menuStoreItems } from 'store/slices/menuSlice';

const { Header, Footer } = Layout;

export default function GnbMenu(props) {
  const {
    gnbMenus,
    selectGnb,
    children,
    languageOptions,
    onChangeLanguage,
    handleSetMobile,
    isMobile,
    handleUnsetMobile,
  } = props;
  const menus = useSelector(menuStoreItems);
  const { user } = useSelector((state) => ({
    user: state?.auth,
  }));
  const { handleOpenTabs, handleOpenHomeTab } = useTabs();
  const { handleOpenModal, handleCloseModal } = useModal();
  const avatarText = user?.userIdInf?.[0]?.toUpperCase();

  const mobileMenus = useMemo(() => {
    let mobileMenus = menus?.filter((menu) => mobileMenuIdArr.includes(menu?.menuId)) ?? [];
    const mobileMain = mobileMenus.find((item) => item.menuId == 'MEUI_00033');
    if (mobileMain) {
      mobileMenus = [mobileMain].concat(mobileMenus.filter((item) => item.menuId != 'MEUI_00033'));
    }

    return mobileMenus;
  }, [menus]);

  const handleMenuItemClick = (menu) => {
    handleCloseModal();
    handleOpenTabs(menu);
  };

  const handleHamburgerButtonClick = () => {
    const childrenComponent = (
      <Flex vertical>
        <Typography.Title className="title">태블릿 메뉴 목록</Typography.Title>
        <Flex vertical className="mobileModalMenuList">
          {mobileMenus.map((item, i) => (
            <Flex
              key={`tablet-menu-item-${i}`}
              justify="center"
              className="mobileModalItem"
              onClick={() => handleMenuItemClick(item)}
            >
              {item.menuNm}
            </Flex>
          ))}
        </Flex>
        <Button className="tabletModeExitButton" onClick={() => handleUnsetMobile(true)}>
          Tablet mode off
        </Button>
      </Flex>
    );
    const params = {
      title: '',
      onCancel: () => handleUnsetMobile(false),
      children: childrenComponent,
      maskClosable: false,
      footer: null,
      className: 'mobileModal',
    };
    handleOpenModal(params);
  };

  return (
    <Layout>
      {isMobile === true ? (
        <Header className="mobileHeaderWrapper">
          <MenuOutlined className="hamburger" size={60} onClick={handleHamburgerButtonClick} />
          <Typography.Title className="mobileTitle">DWIC TABLET MODE</Typography.Title>
          <Avatar size={40} className="avatar">
            {avatarText}
          </Avatar>
        </Header>
      ) : (
        <Header className="headerWrapper">
          <Row>
            <Col sm={6} xl={4} className="logo" style={{ cursor: 'pointer' }} onClick={() => handleOpenHomeTab()}>
              <span>DX</span>
              <span>Platform</span>
            </Col>
            <Col sm={12} xl={16}>
              <Menu
                style={{ justifyContent: 'center' }}
                theme="dark"
                mode="horizontal"
                onClick={(e) => selectGnb(e.key)}
                items={gnbMenus}
              />
            </Col>
            <Col sm={6} xl={4}>
              <Space size="middle">
                <Switch defaultChecked={false} onChange={handleSetMobile} />
                <Select
                  className="headerSelect"
                  defaultValue="korean"
                  onChange={onChangeLanguage}
                  style={{ width: 100, height: 34 }}
                  options={languageOptions}
                />
                {/* <ExclamationCircleOutlined /> */}
                <BellOutlined />
                <Popover
                  trigger="click"
                  content={
                    <UserPopoverContent
                      {...user}
                      avatarText={
                        <Avatar size={36} style={{ backgroundColor: `#b3cf0a` }}>
                          {avatarText}
                        </Avatar>
                      }
                    />
                  }
                >
                  <Avatar size={24} style={{ cursor: 'pointer' }}>
                    {avatarText}
                  </Avatar>
                </Popover>
              </Space>
            </Col>
          </Row>
        </Header>
      )}
      {children}
      <Footer
        style={{
          textAlign: 'center',
        }}
      ></Footer>
    </Layout>
  );
}
