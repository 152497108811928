import { createSlice } from '@reduxjs/toolkit';
import { removeEmptyChildrenService, setKeyAndLabel } from 'service/menus';

const initialState = {
  items: [],
  // status: 'idle', // can be 'idle', 'loading', 'succeeded', or 'failed'
  /* 메뉴 페이지의 실제 폴더 경로를 작성한다. */
  announcement: null,
  faq: null,
  qna: null,
  guide: null,
};

const menuSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    /* 사용자별 메뉴목록 가져오기 */
    getMenusRequest(state, action) {
      state.status = 'loading';
    },
    getMenusSuccess(state, action) {
      /* 메뉴 렌더링전 children 키에대한 처리필요 */
      const processingMenu1 = removeEmptyChildrenService(action.payload);
      const processingMenu2 = setKeyAndLabel(processingMenu1);
      state.items = processingMenu2;
      state.status = 'succeeded';
    },
    getMenusFail(state, action) {
      state.status = 'failed';
    },

    /* 역할별 페이지 컴포넌트 목록 가져오기 */
    getRoleCmpRequest(state, action) {
      const roleList = action.payload.role.map((item) => encodeURIComponent(item)).join('&roleList=');
      action.payload = { menuId: action.payload.menuId, roleList };
    },
    getRoleCmpSuccess(state, action) {
      state[action.payload.menuPath] = action.payload.data;
    },
    getRoleCmpFail(state, action) {},
  },
});

export const { getMenusRequest, getMenusSuccess, getMenusFail, getRoleCmpRequest, getRoleCmpSuccess, getRoleCmpFail } =
  menuSlice.actions;
/* 메뉴 상태 아이템 */
export const menuStoreItems = (state) => state.menus.items;
/* COMMON 컴포넌트 상테 아이템 */
export const announceCmpStorItems = (state) => state.menus.announcement;
export const faqCmpStorItems = (state) => state.menus.faq;
export const qnaCmpStorItems = (state) => state.menus.qna;
export const guideCmpStorItems = (state) => state.menus.guide;
/* ANALYSIS 컴포넌트 상태 아이템 */
export const impStoreItems = (state) => state.menus.improvemsr;
export const confirmImpStoreItems = (state) => state.menus.confirm;
export const applyImpStoreItems = (state) => state.menus.apply;
export const evaluateImpStoreItems = (state) => state.menus.evaluate;
/* ADMIN 컴포넌트 상태 아이템 */
export const companyUserStoreItems = (state) => state.menus.companyuser;

export default menuSlice.reducer;
