import { createSlice } from '@reduxjs/toolkit';

/* 각 페이지별 Slice로 이동예정 */

/* [커뮤니티 등록] FORM처럼 동작하는 모달안의 데이터 초기상태 */
const INIT_PAGE_MODAL_STATE = {
  title: '',
  content: '',
  imageList: [],
  isButtonClick: [false],
};

const initialState = {
  items: {},
  modalCollapseState: null,
  alertItems: {},
  /* [커뮤니티 등록] 상태 */
  pageModalState: INIT_PAGE_MODAL_STATE,
};

const menuSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal(state, action) {
      state.items = action.payload;
    },
    closeModal(state) {
      state.items = {};
    },
    openAllCollapse(state, action) {
      state.modalCollapseState = action.payload;
    },
    closeAllCollapse(state, action) {
      state.modalCollapseState = action.payload;
    },
    openAlert(state, action) {
      state.alertItems = action.payload;
    },
    closeAlert(state) {
      state.alertItems = {};
    },
    /* [커뮤니티 등록] 모달안의 데이터 상태관리 필요시 사용 */
    /* 1. 모달 안의 상태 SET */
    setPageModalState(state, action) {
      /* 이미지 리스트 처리 */
      const imageList = state.pageModalState.imageList.map((item) => item);
      const payloadKeyList = Object.keys(action.payload);
      if (payloadKeyList.includes('image')) {
        imageList.push(action.payload.image);
      }
      /* image 키는 관리X */
      delete action.payload?.image;

      state.pageModalState = { ...state.pageModalState, ...action.payload, imageList };
    },
    /* 2. 모달 안의 상태 RESET */
    resetPageModalState(state, action) {
      state.pageModalState = INIT_PAGE_MODAL_STATE;
    },
  },
});

export const {
  openModal,
  closeModal,
  openAllCollapse,
  closeAllCollapse,
  openAlert,
  closeAlert,
  setPageModalState,
  resetPageModalState,
} = menuSlice.actions;

/* 모달 버튼 클릭 상태 */
export const buttonClickState = (state) => state.modal.isButtonClick;
/* [커뮤니티 등록]모달 상태 */
export const pageModalStoreItems = (state) => state.modal.pageModalState;

export default menuSlice.reducer;
