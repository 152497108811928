import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    /* 로그인 */
    signInRequest(state, action) {},
    signInSuccess(state, action) {
      return action.payload;
    },
    signInFail(state, action) {
      return action.payload;
    },
    /* 로그아웃 */
    signOut(state, action) {
      return null;
    },
    /* 비밀번호 변경 */
    callChangePwRequest(state, action) {
      const changePwState = { ...state, ...action.payload };
      return changePwState;
    },
    changePwRequest(state, action) {},
    changePwProcess(state, action) {
      return action.payload;
    },
    /* ===================================================== */
    /* ====================개발예정 건=================== */
    /* ===================================================== */
    /* 비밀번호 초기화 */
    callResetPw(state, action) {
      return action.payload;
    },
    resetPw(state, action) {},
    resetPwSuccess(state, action) {
      return action.payload;
    },
    resetPwFail(state, action) {
      return action.payload;
    },
    /* 회원가입 Deprecated */
    register(state, action) {
      return action.payload;
    },
  },
});

export const {
  signInRequest,
  signInSuccess,
  signInFail,
  signOut,
  callChangePwRequest,
  changePwRequest,
  changePwProcess,
  register,
  callResetPw,
  resetPw,
  resetPwSuccess,
  resetPwFail,
} = authSlice.actions;

export default authSlice.reducer;
