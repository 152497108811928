import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'ag-grid-charts-enterprise';
import { LicenseManager } from 'ag-grid-charts-enterprise';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter as BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'store/index';
import App from './App';
import './App.css';
import reportWebVitals from './reportWebVitals';

// 추후 라이센스 구매 시 여기에 라이센스 키 적용
LicenseManager.setLicenseKey('');

export const persistStore = persistor;

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: 0,
    },
  },
});

document.addEventListener('DOMContentLoaded', () => {
  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>,
  );
});

reportWebVitals();
